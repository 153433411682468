import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import imgWorld from './assets/images/are_world.png'
import imgMoney from './assets/images/money.png'
import Mxflag from './assets/images/Flag_of_Mexico.inline.svg'
import Coflag from './assets/images/Flag_of_Colombia.inline.svg'
import Clflag from './assets/images/Flag_of_Chile.inline.svg'
import Peflag from './assets/images/Flag_of_Peru.inline.svg'
import Ecflag from './assets/images/Flag_of_Ecuador.inline.svg'
import './styles.scss'

const SomosCountries: React.FC = () => {

  const [tabActive, setTabActive] = useState('acquisition')

  return (
    <section className="somos-bg_countries py-layout-4">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <h4 className="somos_title"><FormattedMessage id="somos.title_country" /></h4>
            <h5 className="somos_subtitle"><FormattedMessage id="somos.subtitle_country" /></h5>
            <hr className="somos_division" />
          </div>
          <div className="col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
            <img src={imgWorld} className="somos-img_world" alt="World" />
          </div>
        </div>
        <div className="row mt-layout-2 justify-content-center">
            <div className="col-md-3 px-0" onClick={() => setTabActive('acquisition')} ><p className={`tab corner-left ${ tabActive === 'acquisition' ? 'tab-active' : ''}`} ><FormattedMessage id="somos.select_service1" /></p></div>
            <div className="col-md-3 px-0" onClick={() => setTabActive('gateway')} ><p className={`tab ${ tabActive === 'gateway' ? 'tab-active' : ''}`} ><FormattedMessage id="somos.select_service2" /></p></div>
            <div className="col-md-3 px-0" onClick={() => setTabActive('aggregator')} ><p className={`tab corner-right ${ tabActive === 'aggregator' ? 'tab-active' : ''}`} ><FormattedMessage id="somos.select_service3" /></p></div>
        </div>
        <div className="row">
          <div className="col-md-6 order-2 order-lg-1">
            <img src={imgMoney} className="somos-img_money"  alt="money" />
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center order-1 order-lg-1">
            <div>
              <h4 className="somos-title_service" ><FormattedMessage id={`somos.service.title_${tabActive}`} /></h4>
              <p className="somos-description_service" ><FormattedMessage id={`somos.service.description_${tabActive}`} /></p>
              <p className="somos-text_available"><FormattedMessage id="somos-country_available" /></p>
              <div className="somos-group_flags">
                {
                  tabActive !== 'acquisition' &&
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Ecflag className="flag somos-flag_country m-0" />
                  </div>
                }
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <Peflag className="flag somos-flag_country" />
                </div>
                {
                  tabActive !== 'gateway' && 
                  <div className="d-flex justify-content-center align-items-center flex-column">
                    <Mxflag className="flag somos-flag_country" />
                  </div>
                }
                <div className={`${tabActive === 'acquisition' ? 'ml-3' : ''} d-flex justify-content-center align-items-center flex-column`}>
                  <Coflag className={`${tabActive !== 'acquisition' && 'flag'} somos-flag_country`} />
                </div>
                <div className={`${tabActive === 'acquisition' ? 'ml-3' : ''} d-flex justify-content-center align-items-center flex-column`}>
                  <Clflag className={`${tabActive !== 'acquisition' && 'flag'} somos-flag_country`} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SomosCountries