import React from 'react'
import { FormattedMessage } from 'react-intl';
import LocalizedLink from '../../LocalizedLink/LocalizedLink';

import './styles.scss'

interface SomosEntryBlogProps {
  data: Array<{
    slug: string;
    mainImage: {
      localFile: {
        publicURL: string;
      }
    };
    title: string;
    body: {
      childMarkdownRemark: {
        excerpt: string;
      }
    }
  }> | [];
}

const SomosEntryBlog: React.FC<SomosEntryBlogProps> = ({
  data
}) => {
  const listPosts = data.edges.slice(0, 3)
  return (
    <section className="container py-layout-3">
        <h3 className="text-center text-primary" >
          <FormattedMessage id="ebook.blog_entry.title" />
        </h3>
      <div className="row">
      {listPosts.map((post: any, index: number) => {
        return (
          <div className="col-md-4" key={index}>
            <div className="card">
              <div className="card-img-top" >
                <img className=" img-card" src={post.node.mainImage.localFile.publicURL} alt="" />
              </div>
              <div className="card-body">
                <h5 className="card-title text-primary">{post.node.title}</h5>
                <p className="text-dark-gray-2">{post.node.body.childMarkdownRemark.excerpt}</p>
                <LocalizedLink to={`/blog/${post.node.slug}`}>
                  <FormattedMessage id="ebook.see_more"/>
                </LocalizedLink>
              </div>
            </div>
          </div>    
        )
      })}
      </div>
    </section>
  )
}

export default SomosEntryBlog;