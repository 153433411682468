import React from 'react'

import ImgSomos from './assets/images/somos.inline.svg'
import { FormattedMessage } from 'react-intl'
import imgGalaxy from './assets/images/somos.svg'
// import GalaxyImg from './assets/images/galaxy.inline.svg'
import './styles.scss'


const SomosHero: React.FC = () => {
  return (
    <section className="hero bg-somos-hero">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-sm-12 py-layout-3 campaign-title_hero">
            <div className="left container-somos">
              <ImgSomos />
              <h1 className="text-white somos-hero_title">
                <FormattedMessage id="somos.title_hero" />
              </h1>
            </div>
          </div>
          <div className="col-md-7 col-sm-12">
            <img src={imgGalaxy} className="mdp__hero-img" />
          </div>
        </div>
      </div>
      <div className="hero-somos-border"></div>
    </section>
  )
}

export default SomosHero