import React from 'react'
import IndexLayout from '../layouts'
import Footer from '../components/Footer/Footer';
import { LocaleTypes } from '../data/locales';
import SomosForm from '../components/Somos/SomosForm/SomosForm';
import SomosHero from '../components/Somos/SomosHero/SomosHero';
import SomosVideo from '../components/Somos/SomosVideo/SomosVideo';
import SomosCountries from '../components/Somos/SomosCountries/SomosCountries';
import SomosEntryBlog from '../components/Somos/SomosEntryBlog/SomosEntryBlog';

import { graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';

interface SomosProps {
  data: any;
  pathContext: {
    locale: LocaleTypes;
  };
  location: Location;
}

const somos: React.FC<SomosProps> = ({data, pathContext, location}) => {
  const { blogPosts } = data
  
  const content = () => (
    <>
      <SomosHero />
  
      <SomosVideo />

      <SomosCountries />

      <SomosEntryBlog data={blogPosts} />
      
      <Footer theme={'primary'} />
    </>
  )

  return (
    <IndexLayout locale={pathContext.localeCode} location={location} render={content} />
  )
}

export default somos

export const query = graphql`
  query($localeCode: String!) {
    blogPosts: allContentfulBlogPost(sort: {order: DESC, fields: date}, filter: {node_locale: {eq: $localeCode}}) {
      edges {
        node {
          title
          slug
          body {
            childMarkdownRemark {
              excerpt
            }
          }
          mainImage {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;